import store from '@/store'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/app-home',
    name: 'app-home',
    component: () => import(/* webpackChunkName: "app-home" */ '../views/AppHomeView.vue')
  },
  {
    path: '/stops',
    name: 'stops',
    component: () => import(/* webpackChunkName: "stops" */ '../views/StopsView.vue')
  },
  {
    path: '/stops/:id',
    name: 'stop-details',
    component: () => import(/* webpackChunkName: "stop-details" */ '../views/StopDetailsView.vue')
  },
  {
    path: '/tours',
    name: 'tours',
    component: () => import(/* webpackChunkName: "tours" */ '../views/ToursView.vue')
  },
  {
    path: '/tours/:id',
    name: 'tour-details',
    component: () => import(/* webpackChunkName: "tour-details" */ '../views/TourDetailsView.vue')
  },
  {
    path: '/tours/:tourId/days/:id',
    name: 'day-details',
    component: () => import(/* webpackChunkName: "day-details" */ '../views/DayDetailsView.vue')
  },
  {
    path: '/stops/:id',
    name: 'stop-details',
    component: () => import(/* webpackChunkName: "stop-details" */ '../views/StopDetailsView.vue')
  },
  {
    path: '/pois',
    name: 'pois',
    component: () => import(/* webpackChunkName: "pois" */ '../views/POIsView.vue')
  },
  {
    path: '/pois/:id',
    name: 'poi-details',
    component: () => import(/* webpackChunkName: "poi-details" */ '../views/POIDetailsView.vue')
  },
  {
    path: '/pois/:poiId/promotions/:id',
    name: 'promo-details',
    component: () => import(/* webpackChunkName: "promo-details" */ '../views/PromoDetailsView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsView.vue')
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import(/* webpackChunkName: "about-us" */ '../views/AboutUsView.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "users" */ '../views/UsersView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, _from, next) => {
  if (to.name !== 'login' && !store.getters.isLoggedIn) next({ name: 'login' })
  else if (to.name === 'login' && store.getters.isLoggedIn) next({ name: 'home' })
  else next()
})

export default router
