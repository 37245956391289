import { User } from '@/models/User'
import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'

interface State {
  auth: {
    user?: User
    token?: string
    refreshToken?: string
  }
}

export const key: InjectionKey<Store<State>> = Symbol('')

export default createStore<State>({
  state: {
    auth: {
      user: undefined,
      token: undefined,
      refreshToken: undefined
    }
  },
  getters: {
    isLoggedIn: (state) => !!state.auth.token,
    authToken: (state) => state.auth.token,
    refreshToken: (state) => state.auth.refreshToken
  },
  mutations: {
    setUser (state, user: User) {
      state.auth.user = user
    },
    setToken (state, token: string) {
      state.auth.token = token
    },
    setRefreshToken (state, refreshToken: string) {
      state.auth.refreshToken = refreshToken
    }
  },
  actions: {
    login ({ commit }, credentials: { user: User, token: string, refreshToken: string}) {
      commit('setUser', credentials.user)
      commit('setToken', credentials.token)
      commit('setRefreshToken', credentials.refreshToken)
    },
    logout ({ commit }) {
      commit('setUser', undefined)
      commit('setToken', undefined)
      commit('setRefreshToken', undefined)
    }
  },
  modules: {
  },
  strict: process.env.NODE_ENV !== 'production'
})

export function useStore () {
  return baseUseStore(key)
}
