import { createApp } from 'vue'
import App from './App.vue'
import './index.css'
import router from './router'
import store, { key } from './store'
import 'ts-array-extensions'
import VueGoogleMap from '@fawmi/vue-google-maps'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

try {
  const creds = sessionStorage.getItem('credentials')
  if (creds) {
    store.dispatch('login', JSON.parse(creds))
  }
} catch { }

createApp(App)
  .use(store, key)
  .use(router)
  .use(VueGoogleMap, {
    load: {
      key: 'AIzaSyBjjp_ZpemF2lnd5aPlhTkHVBRpNcCQeBY',
      libraries: ['places']
    }
  })
  .component('Datepicker', Datepicker)
  .mount('#app')
