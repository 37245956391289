import parseISO from 'date-fns/parseISO'

function hasProp<K extends PropertyKey> (object: object, prop: K): object is Record<K, unknown> {
  return prop in object
}

//
// String parsing
//

export function extractString<K extends PropertyKey> (object: object | null | undefined, prop: K): string {
  if (object && typeof object === 'object' && hasProp(object, prop)) {
    const property = object[prop]
    if (typeof property === 'string') { return property }
  }
  throw new Error(`Property ${prop} is not a string`)
}

export function extractStringOpt<K extends PropertyKey> (object: object | null | undefined, prop: K): string | undefined {
  try { return extractString(object, prop) } catch { return undefined }
}

export function extractStringOrDefault<K extends PropertyKey> (object: object | null | undefined, prop: K, fallback = ''): string {
  return extractStringOpt(object, prop) || fallback
}

//
// Number parsing
//

export function extractNumber<K extends PropertyKey> (object: object | null | undefined, prop: K): number {
  if (object && typeof object === 'object' && hasProp(object, prop)) {
    const property = object[prop]
    if (typeof property === 'number') { return property }
  }
  throw new Error(`Property ${prop} is not a number`)
}

export function extractNumberOpt<K extends PropertyKey> (object: object | null | undefined, prop: K): number | undefined {
  try { return extractNumber(object, prop) } catch { return undefined }
}

export function extractNumberOrDefault<K extends PropertyKey> (object: object | null | undefined, prop: K, fallback = 0): number {
  return extractNumberOpt(object, prop) || fallback
}

//
// Boolean parsing
//

export function extractBoolean<K extends PropertyKey> (object: object | null | undefined, prop: K): boolean {
  if (object && typeof object === 'object' && hasProp(object, prop)) {
    const property = object[prop]
    if (typeof property === 'boolean') { return property }
  }
  throw new Error(`Property ${prop} is not a boolean`)
}

export function extractBooleanOpt<K extends PropertyKey> (object: object | null | undefined, prop: K): boolean | undefined {
  try { return extractBoolean(object, prop) } catch { return undefined }
}

export function extractBooleanOrDefault<K extends PropertyKey> (object: object | null | undefined, prop: K, fallback = false): boolean {
  return extractBooleanOpt(object, prop) || fallback
}

//
// Enum parsing
//

export function extractEnum<K extends PropertyKey, T> (object: object | null | undefined, prop: K, enumType: T): keyof T {
  const value = extractString(object, prop)
  return (enumType as any)[value] as keyof T
}

export function extractEnumOpt<K extends PropertyKey, T> (object: object | null | undefined, prop: K, enumType: T): keyof T | undefined {
  try { return extractEnum(object, prop, enumType) } catch { return undefined }
}

export function extractEnumOrDefault<K extends PropertyKey, T> (object: object | null | undefined, prop: K, enumType: T, fallback: keyof T): keyof T {
  return extractEnumOpt(object, prop, enumType) || fallback
}

//
// Date parsing
//

export function extractISODate<K extends PropertyKey> (object: object | null | undefined, prop: K): Date {
  if (object && typeof object === 'object' && hasProp(object, prop)) {
    const property = object[prop]
    if (typeof property === 'string') { return parseISO(property) }
  }
  throw new Error(`Property ${prop} is not valid`)
}

export function extractISODateOpt<K extends PropertyKey> (object: object | null | undefined, prop: K): Date | undefined {
  try { return extractISODate(object, prop) } catch { return undefined }
}

export function extractISODateOrDefault<K extends PropertyKey> (object: object | null | undefined, prop: K, fallback = new Date()): Date {
  return extractISODateOpt(object, prop) || fallback
}

//
// Array parsing
//

export function extractArray<K extends PropertyKey> (object: object | null | undefined, prop: K): unknown[] {
  if (object && typeof object === 'object' && hasProp(object, prop)) {
    const property = object[prop]
    if (Array.isArray(property)) { return property }
  }
  throw new Error(`Property ${prop} is not an array`)
}

export function extractArrayOpt<K extends PropertyKey> (object: object | null | undefined, prop: K): unknown[] | undefined {
  try { return extractArray(object, prop) } catch { return undefined }
}

export function extractArrayOrDefault<K extends PropertyKey> (object: object | null | undefined, prop: K, fallback: unknown[] = []): unknown[] {
  return extractArrayOpt(object, prop) || fallback
}

//
// Unknown parsing
//

export function extractUnknown<K extends PropertyKey> (object: object | null | undefined, prop: K): unknown {
  if (object && typeof object === 'object' && hasProp(object, prop)) {
    return object[prop]
  }
  throw new Error(`Property ${prop} is not defined`)
}

export function extractUnknownOpt<K extends PropertyKey> (object: object | null | undefined, prop: K): unknown | undefined {
  try { return extractUnknown(object, prop) } catch { return null }
}
