import axios from 'axios'
import store from '@/store'
import router from '@/router'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import * as AuthAPI from '@/api/modules/AuthAPI'

const client = axios
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL ?? 'http://localhost:3000/api/'
axios.interceptors.request.use(config => {
  const token = store.getters.authToken
  if (config.headers && token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

createAuthRefreshInterceptor(axios,
  async failedRequest => {
    const refreshToken = store.getters.refreshToken
    if (!refreshToken) { throw new Error('No refresh token') }
    try {
      const tokenRefreshResponse = await AuthAPI.refreshAuthToken(refreshToken)
      sessionStorage.setItem('credentials', JSON.stringify(tokenRefreshResponse))
      store.dispatch('login', tokenRefreshResponse)
      failedRequest.response.config.headers.Authorization = `Bearer ${tokenRefreshResponse.token}`
    } catch (error) {
      console.log(error)
      sessionStorage.removeItem('credentials')
      store.dispatch('logout')
      router.push('/login')
      throw error
    }
  }, {
    statusCodes: [401, 403]
  })

export const httpClient = client
