import { Identifiable } from './Identifiable'
import { extractArrayOrDefault, extractEnumOpt, extractISODateOrDefault, extractNumberOpt, extractString, extractStringOpt } from '@/utils/parsing'

export enum Gender {
  male = 'male',
  female = 'female',
  nonBinary = 'nonBinary',
  undisclosed = 'undisclosed'
}

enum Role {
  admin = 'admin',
  user = 'user',
}

export class User implements Identifiable {
  id: string
  avatarURL?: string
  firstName?: string
  lastName?: string
  email?: string
  birthdayDay?: number
  birthdayMonth?: number
  birthdayYear?: number
  gender?: keyof typeof Gender
  location?: string
  purchasedTours: string[]
  bookingConfirmationCode?: string
  roles: (keyof typeof Role)[]
  createdAt: Date

  constructor (
    id: string,
    roles: (keyof typeof Role)[],
    purchasedTours: string[],
    createdAt: Date,
    avatarURL?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    birthdayDay?: number,
    birthdayMonth?: number,
    birthdayYear?: number,
    gender?: keyof typeof Gender,
    location?: string,
    bookingConfirmationCode?: string
  ) {
    this.id = id
    this.roles = roles
    this.avatarURL = avatarURL
    this.firstName = firstName
    this.lastName = lastName
    this.email = email
    this.birthdayDay = birthdayDay
    this.birthdayMonth = birthdayMonth
    this.birthdayYear = birthdayYear
    this.gender = gender
    this.location = location
    this.purchasedTours = purchasedTours
    this.createdAt = createdAt
    this.bookingConfirmationCode = bookingConfirmationCode
  }

  static fromJSON (json?: unknown): User | undefined {
    if (!json || typeof json !== 'object') { return undefined }

    try {
      return new User(
        extractString(json, '_id'),
        extractArrayOrDefault(json, 'roles', []).compactMap(d => typeof d === 'string' ? Role[d as keyof typeof Role] : undefined),
        extractArrayOrDefault(json, 'purchasedTours').compactMap(t => typeof t === 'string' ? t : undefined),
        extractISODateOrDefault(json, 'createdAt'),
        extractStringOpt(json, 'avatarURL'),
        extractStringOpt(json, 'firstName'),
        extractStringOpt(json, 'lastName'),
        extractStringOpt(json, 'email'),
        extractNumberOpt(json, 'birthdayDay'),
        extractNumberOpt(json, 'birthdayMonth'),
        extractNumberOpt(json, 'birthdayYear'),
        extractEnumOpt(json, 'gender', Gender),
        extractStringOpt(json, 'location'),
        extractStringOpt(json, 'bookingConfirmationCode')
      )
    } catch {
      return undefined
    }
  }
}
