import * as AuthAPI from '@/api/modules/AuthAPI'
import store from '@/store'

export async function login (username: string, password: string) {
  const credentials = await AuthAPI.login(username, password)
  if (credentials.user.roles.includes('admin')) {
    sessionStorage.setItem('credentials', JSON.stringify(credentials))
    store.dispatch('login', credentials)
  } else {
    throw new Error('You can\'t access this page')
  }
}

export async function logout () {
  sessionStorage.removeItem('credentials')
  store.dispatch('logout')
}
