import { User } from '@/models/User'
import { httpClient } from '..'

export async function login (username: string, password: string) {
  const response = await httpClient.post('v1/auth/token', {
    username: username,
    password: password,
    provider: 'usernamePassword'
  }, { skipAuthRefresh: true } as any)

  const user = User.fromJSON(response.data?.data?.user)
  const token = response.data?.data?.token
  const refreshToken = response.data?.data?.refreshToken

  if (user && token && refreshToken) {
    return {
      user: user,
      token: token,
      refreshToken: refreshToken
    }
  } else {
    throw new Error('Invalid credentials')
  }
}

export async function refreshAuthToken (refreshToken: string) {
  const response = await httpClient.post('v1/auth/token', {
    token: refreshToken,
    provider: 'refreshToken'
  }, { skipAuthRefresh: true } as any)

  const user = User.fromJSON(response.data?.data?.user)
  const token = response.data?.data?.token
  const newRefreshToken = response.data?.data?.refreshToken

  if (user && token && newRefreshToken) {
    return {
      user: user,
      token: token,
      refreshToken: newRefreshToken
    }
  } else {
    throw new Error('Invalid credentials')
  }
}
